import { EdamamRecipeResponse } from "./external/edamam";

const BASE_URL =
  process.env.REACT_APP_JSON_SERVER_URL || window.location.origin;

export const getRecipes = async () => {
  const res = await fetch(`${BASE_URL}/recipes`);
  return res.json();
};

export const getRecipeById = async (id: string) => {
  const res = await fetch(`${BASE_URL}/recipes/${id}`);
  return res.json();
};

export const postRecipe = async (recipe: Recipe) => {
  const res = await fetch(`${BASE_URL}/recipes`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(recipe),
  });
  return res.json();
};

export const putRecipe = async (id: number, recipe: Recipe) => {
  const res = await fetch(`${BASE_URL}/recipes/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(recipe),
  });
  return res.json();
};

export interface Recipe extends EdamamRecipeResponse {
  id: number;
  directions: string[];
  source: string;
  name: string;
  difficulty?: string;
  inMealPlanner?: boolean;
  image: string;
  favorite?: boolean;
  made?: boolean;
  status?: string;
}
