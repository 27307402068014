/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import "twin.macro";

import { postRecipe } from "../../api/recipes";
import RecipeMetaFields, { convertBase64 } from "./RecipeMetaFields";

import { parseRecipe, EdamamRecipeResponse } from "../../api/external/edamam";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";

type FormInput = {
  name: string;
  source?: string;
  yield?: number;
  difficulty: number;
  inMealPlanner: boolean;
  ingredientsBlob: string;
  directionsBlob: string;
  image?: FileList;
};

const Recipes: React.FC = () => {
  const navigate = useNavigate();
  const [edamamError, setEdamamError] = useState<string>();
  const methods = useForm<FormInput>({
    defaultValues: { difficulty: 2, inMealPlanner: true },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const parseRecipeQuery = useMutation<
    EdamamRecipeResponse,
    Error,
    { title: string; ingredients: string[] }
  >(({ title, ingredients }) => parseRecipe(title, ingredients));

  const createRecipeMutation = useMutation<any, Error, any>((newRecipe) =>
    postRecipe(newRecipe)
  );

  const onSubmit: SubmitHandler<FormInput> = ({
    ingredientsBlob,
    directionsBlob,
    image,
    ...restValues
  }) => {
    parseRecipeQuery.mutate(
      { title: restValues.name, ingredients: ingredientsBlob.split("\n") },
      {
        onSuccess: async (edmamParsedRecipe) => {
          if (edmamParsedRecipe.error) {
            return setEdamamError(edmamParsedRecipe.error);
          }
          const base64Image =
            image && image.length > 0
              ? await convertBase64(image[0])
              : undefined;
          createRecipeMutation.mutate(
            {
              ...restValues,
              ...edmamParsedRecipe,
              directions: directionsBlob.split("\n"),
              yield: restValues.yield,
              ...(base64Image ? { image: base64Image } : {}),
            },
            {
              onSuccess: (data) => {
                return navigate(`/recipes/${data.id}`);
              },
            }
          );
        },
      }
    );
  };

  return (
    <article>
      <h2 tw="text-xl">Create a new Recipe</h2>
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          tw="flex flex-col gap-6 w-[80%]"
        >
          <RecipeMetaFields />

          <label tw="flex flex-col">
            <div>
              Ingredients:{" "}
              {errors.ingredientsBlob && (
                <span tw="text-error">This field is required</span>
              )}
            </div>
            <textarea
              tw="textarea textarea-bordered h-32"
              {...register("ingredientsBlob", { required: true })}
            />
          </label>

          <label tw="flex flex-col">
            Directions:
            <textarea
              tw="textarea textarea-bordered h-32"
              {...register("directionsBlob")}
            />
          </label>
          {edamamError && (
            <span tw="text-error">
              Ingredients parsing API error: {edamamError}
            </span>
          )}
          <input type="submit" tw="btn btn-primary" />
        </form>
      </FormProvider>
    </article>
  );
};

export default Recipes;
