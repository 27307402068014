import { Recipe } from "../api/recipes";
import { EdamamIngredient } from "../api/external/edamam";

const KG_TO_LB_RATE = 0.45359237;
export const gramToPoundsString = (weight: number) => {
  const nearExact = weight / (KG_TO_LB_RATE * 1000);
  const lbs = Math.floor(nearExact);
  const oz = Math.ceil((nearExact - lbs) * 16);

  return [lbs > 0 && `${lbs}lbs`, oz > 0 && `${oz}oz`]
    .filter((w) => !!w)
    .join(" ");
};

type parsedIngredientWithText = Exclude<
  EdamamIngredient["parsed"],
  undefined
>[0] & {
  ogText: string;
};
interface parsedIngredients {
  totalWeight: number;
  imperialWeight: string;
  ingredients: parsedIngredientWithText[];
}

const useShoppingList = (mealPlan: Recipe[]) => {
  const flatIngredients = mealPlan.flatMap((recipe) =>
    recipe.ingredients.flatMap((ingr) =>
      ingr?.parsed
        ? ingr.parsed.flatMap((p) => ({
            ...p,
            ogText: `${ingr.text} from (${recipe.name})`,
          }))
        : []
    )
  );

  const categorizedIngredients = flatIngredients.reduce<
    Record<string, parsedIngredientWithText[]>
  >(
    (acc, ingr) => ({
      ...acc,
      [ingr.food]: acc[ingr.food] ? [...acc[ingr.food], ingr] : [ingr],
    }),
    {}
  );

  const ingredientsWithWeight = Object.entries(categorizedIngredients).reduce<
    Record<string, parsedIngredients>
  >((acc, [food, ingr]) => {
    const totalWeight = ingr.reduce((sum, i) => sum + i.weight, 0);

    return {
      ...acc,
      [food]: {
        ingredients: categorizedIngredients[food],
        totalWeight,
        imperialWeight: gramToPoundsString(totalWeight),
      },
    };
  }, {});

  return { ingredientsWithWeight };
};

export default useShoppingList;
