/** @jsxImportSource @emotion/react */
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import "twin.macro";

import { FaDice, FaHeart, FaCircleCheck } from "react-icons/fa6";

import { putRecipe, Recipe } from "../api/recipes";

const RecipeCard: React.FC<{ recipe: Recipe; children?: React.ReactNode }> = ({
  recipe,
  children,
}) => {
  const queryClient = useQueryClient();

  const updateRecipeMutation = useMutation<any, Error, any>(
    ({ id, newRecipe }) => putRecipe(id, newRecipe)
  );
  const onToggleInMealPlanner = () =>
    updateRecipeMutation.mutate(
      {
        id: recipe.id,
        newRecipe: {
          ...recipe,
          inMealPlanner: !recipe?.inMealPlanner,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("recipes");
        },
      }
    );

  return (
    <div tw="card w-80 h-full bg-base-100 shadow-xl">
      <div
        tw="w-full h-48 bg-cover bg-center bg-base-200 rounded-t-lg mt-6"
        style={{
          backgroundImage: `url('${
            recipe?.image ? recipe?.image : "/placeholder-image.png"
          }')`,
        }}
      >
        {(recipe.favorite || recipe.made) && (
          <div tw="w-8 absolute right-4 top-4 p-2 flex flex-col items-center gap-2 bg-secondary text-secondary-content rounded-b shadow">
            {recipe.favorite && <FaHeart />}
            {recipe.made && <FaCircleCheck />}
          </div>
        )}
      </div>
      <div tw="card-body flex flex-col justify-between">
        <h2 tw="card-title m-0 p-0 flex flex-row justify-between items-start">
          <Link to={`/recipes/${recipe.id}`} tw="text-lg">
            {recipe.name}
          </Link>
          <button onClick={onToggleInMealPlanner}>
            {recipe?.inMealPlanner ? (
              <div
                tw="tooltip"
                data-tip="Included in the meal planner roulette"
              >
                <FaDice tw="text-primary w-6 h-6" />
              </div>
            ) : (
              <div
                tw="tooltip"
                data-tip="Not included in the meal planner roulette"
              >
                <FaDice tw="text-base-300 w-6 h-6" />
              </div>
            )}
          </button>
        </h2>
        {children}
        <div>
          {recipe.difficulty && (
            <div tw="rating gap-1">
              Difficulty:
              <input
                type="radio"
                tw="mask mask-star-2 cursor-default bg-green-400"
                checked={recipe.difficulty === "1"}
                readOnly
              />
              <input
                type="radio"
                tw="mask mask-star-2 cursor-default bg-yellow-400"
                checked={recipe.difficulty === "2"}
                readOnly
              />
              <input
                type="radio"
                tw="mask mask-star-2 cursor-default bg-red-400"
                checked={recipe.difficulty === "3"}
                readOnly
              />
            </div>
          )}
          <div tw="text-sm flex flex-row justify-between">
            <span>
              Calories per serving: {Math.ceil(recipe.calories / recipe.yield)}{" "}
            </span>
            |<span>Serves: {recipe.yield}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipeCard;
