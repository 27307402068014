/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { Outlet, NavLink } from "react-router-dom";
import "twin.macro";

import { FaDice, FaBookBookmark, FaSun, FaMoon } from "react-icons/fa6";

const Layout: React.FC = () => {
  const [darkMode, setDarkMode] = useState(
    window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
  );
  return (
    <div
      tw="bg-base-100 min-h-[100vh] prose max-w-none"
      data-theme={darkMode ? "dim" : "cmyk"}
    >
      <header tw="navbar flex flex-col md:flex-row justify-between items-start md:items-center px-6 py-2 mb-2 border-b ">
        <h1 tw="text-lg my-0">Recipe Book and Meal Planner</h1>
        <nav>
          <ul tw="flex flex-col md:flex-row items-start md:items-center gap-2 list-none my-0">
            <li>
              <label tw="label cursor-pointer flex flex-row gap-1">
                <FaSun />
                <input
                  type="checkbox"
                  tw="toggle"
                  checked={darkMode}
                  onClick={() => setDarkMode(!darkMode)}
                />
                <FaMoon />
              </label>
            </li>
            <li>
              <NavLink to="/roulette" tw="btn btn-link">
                <FaDice tw="w-6 h-6" />
                Meal Planner Roulette
              </NavLink>
            </li>
            <li>
              <NavLink to="/" tw="btn btn-link">
                <FaBookBookmark tw="w-4 h-4" />
                Recipes List
              </NavLink>
            </li>
            <li>
              <NavLink to="/recipes/create" tw="btn btn-primary">
                + Create a new recipe
              </NavLink>
            </li>
          </ul>
        </nav>
      </header>
      <main tw=" max-w-[1200px] mx-auto px-6">
        <Outlet />
      </main>
      <footer></footer>
    </div>
  );
};

export default Layout;
