export const parseRecipe = async (title: string, ingredients: string[]) => {
  const res = await fetch(
    `https://api.edamam.com/api/nutrition-details?` +
      new URLSearchParams({
        app_id: process.env.REACT_APP_EDAMAM_ID as string,
        app_key: process.env.REACT_APP_EDAMAM_KEY as string,
      }),
    {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "https://api.edamam.com",
        "Access-Control-Allow-Credentials": "true",
      },
      body: JSON.stringify({ title, ingr: ingredients }),
    }
  );
  return res.json();
};

type Nutrient = {
  label: string;
  quantity: number;
  unit: string;
};
export type EdamamIngredient = {
  text: string;
  parsed?: {
    quantity: number;
    measure: string;
    foodMatch: string;
    food: string;
    weight: number;
  }[];
};
export type EdamamRecipeResponse = {
  error?: string;
  yield: number;
  calories: number;
  totalWeight: number;
  ingredients: (EdamamIngredient | undefined)[];
  totalNutrients: Record<string, Nutrient>;
  totalDaily: Record<string, Nutrient>;
};
