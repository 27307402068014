export const sendDiscordMessage = async (message: string) => {
  if (!process.env.REACT_APP_DISCORD_WEBHOOK) {
    console.error("No discord token set");
    return;
  }

  await fetch(process.env.REACT_APP_DISCORD_WEBHOOK as string, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ content: message }),
  });
};

export const DISCORD_MSG_LIMIT = 2000;
