/** @jsxImportSource @emotion/react */
import React from "react";
import "twin.macro";

import { useMutation, useQueryClient } from "react-query";
import { Recipe as RecipeResponse, putRecipe } from "../../api/recipes";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";

import RecipeMetaFields, { convertBase64 } from "./RecipeMetaFields";

type FormInput = {
  name: string;
  source?: string;
  yield: number;
  difficulty: string;
  inMealPlanner: boolean;
  image: FileList;
};

const EditRecipeMeta: React.FC<{
  recipe: RecipeResponse;
  onClose: () => void;
}> = ({ recipe, onClose }) => {
  const queryClient = useQueryClient();
  const methods = useForm<FormInput>({
    defaultValues: { ...recipe, image: undefined },
  });
  const { handleSubmit } = methods;

  const updateRecipeMutation = useMutation<any, Error, any>((newRecipe) =>
    putRecipe(recipe.id, newRecipe)
  );

  const onSubmit: SubmitHandler<FormInput> = async ({ image, ...values }) => {
    const base64Image =
      image && image.length > 0 ? await convertBase64(image[0]) : undefined;

    updateRecipeMutation.mutate(
      {
        ...recipe,
        ...values,
        ...(base64Image ? { image: base64Image } : {}),
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("recipe");
          onClose();
        },
      }
    );
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} tw="flex flex-col gap-6 w-[80%]">
        <RecipeMetaFields />
        <div tw="flex flex-row gap-6">
          <button tw="btn" onClick={onClose}>
            Cancel Edit
          </button>
          <input type="submit" tw="btn btn-primary flex-grow" />
        </div>
      </form>
    </FormProvider>
  );
};
export default EditRecipeMeta;
