/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import "twin.macro";

import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import {
  FaDice,
  FaPenToSquare,
  FaXmark,
  FaHeart,
  FaCircleCheck,
} from "react-icons/fa6";

import {
  getRecipeById,
  Recipe as RecipeResponse,
  putRecipe,
} from "../../api/recipes";

import EditRecipeMeta from "./EditRecipeMeta";
import EditRecipeIngredients from "./EditRecipeIngredients";
import EditRecipeDirections from "./EditRecipeDirections";
import NutritionCard from "../../components/NutritionCard";
import SendShoppingListToDiscordButton from "../../components/SendShoppingListToDiscordButton";

const Recipes: React.FC = () => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery<RecipeResponse>(["recipe", id], () =>
    getRecipeById(id as string)
  );

  const updateRecipeMutation = useMutation<any, Error, any>(
    ({ id, newRecipe }) => putRecipe(id, newRecipe)
  );

  const [isEditingMeta, setIsEditingMeta] = useState(false);
  const [isEditingDirections, setIsEditingDirections] = useState(false);
  const [isEditingIngredients, setIsEditingIngredients] = useState(false);

  if (isLoading || !data) {
    return <span tw="loading loading-dots loading-lg" />;
  }

  const onUpdateShallowField = (newField: Record<string, any>) => {
    updateRecipeMutation.mutate(
      {
        id: data.id,
        newRecipe: {
          ...data,
          ...newField,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("recipe");
        },
      }
    );
  };

  return (
    <article>
      {isEditingMeta ? (
        <div tw="mb-6">
          <EditRecipeMeta
            recipe={data}
            onClose={() => setIsEditingMeta(false)}
          />
        </div>
      ) : (
        <section tw="flex flex-col lg:flex-row gap-6 my-6">
          <div tw="flex-grow flex flex-col">
            <div tw="flex flex-col md:flex-row justify-between items-stretch md:items-center">
              <h2 tw="text-xl capitalize flex flex-row items-center gap-2 my-0">
                {data?.name}
                <button
                  onClick={() =>
                    onUpdateShallowField({
                      inMealPlanner: !data?.inMealPlanner,
                    })
                  }
                >
                  {data?.inMealPlanner ? (
                    <div
                      tw="tooltip"
                      data-tip="Included in the meal planner roulette"
                    >
                      <FaDice tw="text-primary" />
                    </div>
                  ) : (
                    <div
                      tw="tooltip"
                      data-tip="Not included in the meal planner roulette"
                    >
                      <FaDice tw="text-base-300" />
                    </div>
                  )}
                </button>
              </h2>
              <button
                tw="btn btn-primary"
                onClick={() => setIsEditingMeta(true)}
              >
                Edit Recipe
              </button>
            </div>
            {data?.source && (
              <a tw="link text-sm break-all" href={data?.source}>
                {data?.source}
              </a>
            )}
            <div
              tw="w-full flex-grow bg-cover bg-center bg-base-200 rounded-lg mt-6 min-h-[320px] max-h-[600px]"
              style={{
                backgroundImage: `url('${
                  data?.image ? data?.image : "/placeholder-image.png"
                }')`,
              }}
            >
              {(data.favorite || data.made) && (
                <div tw="w-8 float-right mr-4 -mt-2 p-2 flex flex-col items-center gap-2 bg-secondary text-secondary-content rounded-b shadow">
                  {data.favorite && <FaHeart />}
                  {data.made && <FaCircleCheck />}
                </div>
              )}
            </div>
            <div tw="flex flex-row justify-between items-center">
              {data?.yield && (
                <div tw="flex flex-row gap-6">
                  {data?.difficulty && (
                    <div tw="flex flex-row gap-2 items-center">
                      <p>Difficulty:</p>
                      <div tw="rating gap-1">
                        <input
                          type="radio"
                          tw="mask mask-star-2 bg-green-400"
                          checked={data?.difficulty === "1"}
                          onClick={() =>
                            onUpdateShallowField({
                              difficulty: "1",
                            })
                          }
                        />
                        <input
                          type="radio"
                          tw="mask mask-star-2 bg-yellow-400"
                          checked={data?.difficulty === "2"}
                          onClick={() =>
                            onUpdateShallowField({
                              difficulty: "2",
                            })
                          }
                        />
                        <input
                          type="radio"
                          tw="mask mask-star-2 bg-red-400"
                          checked={data?.difficulty === "3"}
                          onClick={() =>
                            onUpdateShallowField({
                              difficulty: "3",
                            })
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div tw="flex flex-row gap-6">
                <button
                  tw="btn btn-link"
                  onClick={() =>
                    onUpdateShallowField({
                      favorite: !data.favorite,
                    })
                  }
                >
                  {data.favorite ? "Unfavorite Recipe" : "Favorite Recipe"}
                </button>
                <button
                  tw="btn btn-link"
                  onClick={() =>
                    onUpdateShallowField({
                      made: !data.made,
                    })
                  }
                >
                  {data.made ? "I have not made this" : "I've made this"}
                </button>
              </div>
            </div>
          </div>
          <NutritionCard recipe={data} />
        </section>
      )}
      <div tw="flex flex-row gap-6 w-full">
        <article tw="flex flex-col lg:flex-row p-6 gap-6 bg-base-200 shadow rounded-lg items-start w-full">
          <table tw="min-w-[285px] my-0">
            <thead>
              <tr>
                <td tw="font-bold">Ingredients</td>
                <td tw="text-right">
                  {isEditingIngredients ? (
                    <div tw="tooltip" data-tip="Cancel Editing Ingredients">
                      <button
                        tw="btn btn-link text-xs"
                        onClick={() => setIsEditingIngredients(false)}
                      >
                        <FaXmark />
                      </button>
                    </div>
                  ) : (
                    <div tw="tooltip" data-tip="Edit Ingredients">
                      <button
                        tw="btn btn-link text-xs"
                        onClick={() => setIsEditingIngredients(true)}
                      >
                        <FaPenToSquare />
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            </thead>
            <tbody>
              {isEditingIngredients ? (
                <tr>
                  <td colSpan={2}>
                    <EditRecipeIngredients
                      recipe={data}
                      onClose={() => setIsEditingIngredients(false)}
                    />
                  </td>
                </tr>
              ) : (
                <>
                  {data.ingredients.map(
                    (ingr) =>
                      ingr && (
                        <tr key={ingr.text}>
                          <td colSpan={2}>{ingr.text}</td>
                        </tr>
                      )
                  )}
                  <tr>
                    <td colSpan={2}>
                      <SendShoppingListToDiscordButton
                        mealPlan={[data]}
                        tw="w-full my-2"
                      />
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
          <ul tw="flex-grow min-w-[60%] pl-0">
            <li tw="text-xs flex flex-row justify-between items-center font-bold">
              Directions
              {isEditingDirections ? (
                <div tw="tooltip" data-tip="Cancel Editing Directions">
                  <button
                    tw="btn btn-link text-xs"
                    onClick={() => setIsEditingDirections(false)}
                  >
                    <FaXmark />
                  </button>
                </div>
              ) : (
                <div tw="tooltip" data-tip="Edit Directions">
                  <button
                    tw="btn btn-link text-xs"
                    onClick={() => setIsEditingDirections(true)}
                  >
                    <FaPenToSquare />
                  </button>
                </div>
              )}
            </li>
            {isEditingDirections ? (
              <li tw="flex-grow">
                <EditRecipeDirections
                  recipe={data}
                  onClose={() => setIsEditingDirections(false)}
                />
              </li>
            ) : (
              <>
                {data.directions?.map((directions, i) => (
                  <li key={i} tw="flex flex-row gap-2 py-1">
                    {directions}
                  </li>
                ))}
              </>
            )}
          </ul>
        </article>
      </div>
    </article>
  );
};

export default Recipes;
