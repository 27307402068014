/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { useQuery } from "react-query";
import "twin.macro";

import { useForm } from "react-hook-form";

import { getRecipes, Recipe } from "../../api/recipes";
import ShoppingList from "./ShoppingList";
import RecipeCard from "../../components/RecipeCard";

const generateMealPlan = (
  recipes: Recipe[],
  numMeals: number,
  mealPlan?: Recipe[]
): Recipe[] | undefined => {
  if (mealPlan && mealPlan.length >= numMeals) {
    return mealPlan.filter((recipe) => !!recipe);
  }
  if (mealPlan && mealPlan.length > 0) {
    const recipesPool = recipes.reduce<Recipe[]>((acc, recipe) => {
      if (mealPlan.some((r) => r.id === recipe.id)) {
        return acc;
      }
      const numEntries = recipe.ingredients
        .map((ingr) => ingr?.parsed)
        .flat()
        // leave out small ingredients.
        .filter((ingr) => ingr && ingr.weight > 100)
        .reduce((appearances, ingr) => {
          return (
            appearances +
            mealPlan
              .map((mp) => mp.ingredients.map((mpIngr) => mpIngr?.parsed))
              .flat()
              .flat()
              .filter(
                (mpIngr) =>
                  ingr && mpIngr && mpIngr.foodMatch === ingr.foodMatch
              ).length *
              // idk, what's a good weight?
              3
          );
        }, 1);
      return [...acc, ...Array(numEntries).fill(recipe)];
    }, []);

    const newMealPlan = [
      ...mealPlan,
      recipesPool[Math.floor(Math.random() * recipesPool.length)],
    ];
    return generateMealPlan(recipes, numMeals, newMealPlan);
  }
  return generateMealPlan(recipes, numMeals, [
    recipes[Math.floor(Math.random() * recipes.length)],
  ]);
};

type FormInput = {
  numMeals: number;
  lockedMealPlan?: string[];
  maxDifficulty: string;
};
const Roulette: React.FC = () => {
  const { data, isLoading } = useQuery<Recipe[]>("recipes", getRecipes);
  const [mealPlan, setMealPlan] = useState<Recipe[]>();
  const [submittedNumMeals, setSubmittedNumMeals] = useState(0);

  const { register, watch, handleSubmit } = useForm<FormInput>({
    defaultValues: { numMeals: 3, maxDifficulty: "3" },
  });

  const watchedNumMeals = watch("numMeals");
  const onSubmit = (values: FormInput) => {
    if (!data) {
      return;
    }
    const recipePool = values.maxDifficulty
      ? data.filter(
          (recipe) =>
            recipe.inMealPlanner &&
            // filter out by difficulty
            (!values.maxDifficulty ||
              !recipe.difficulty ||
              parseInt(recipe.difficulty, 10) <=
                parseInt(values.maxDifficulty, 10))
        )
      : data;

    setMealPlan(
      generateMealPlan(
        recipePool,
        values.numMeals,
        values.lockedMealPlan
          ? values.lockedMealPlan.map((r) => JSON.parse(r) as Recipe)
          : []
      )
    );

    setSubmittedNumMeals(values.numMeals);
  };

  if (isLoading || !data) {
    return <span tw="loading loading-dots loading-lg" />;
  }

  return (
    <article>
      <h2>Meal Planner Roulette</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div tw="py-4">
          <label htmlFor="numMeals" tw="flex flex-row justify-between">
            <span>Number of meals to generate</span>{" "}
            <span>{watchedNumMeals}</span>
          </label>
          <input
            type="range"
            min={1}
            max="7"
            tw="range"
            step="1"
            {...register("numMeals", { required: true })}
          />
          <div tw="w-full flex justify-between text-xs px-2">
            {Array(7)
              .fill(1)
              .map((_, i) => (
                <span key={i}>|</span>
              ))}
          </div>
        </div>
        <div tw="rating gap-1 py-2.5">
          <span>Max Difficulty of recipe</span>
          <input
            type="radio"
            {...register("maxDifficulty")}
            tw="mask mask-star-2 bg-green-400"
            value="1"
          />
          <input
            type="radio"
            {...register("maxDifficulty")}
            tw="mask mask-star-2 bg-yellow-400"
            value="2"
          />
          <input
            type="radio"
            {...register("maxDifficulty")}
            tw="mask mask-star-2 bg-red-400"
            value="3"
          />
        </div>
        {mealPlan && mealPlan.length > 0 && (
          <ul tw="flex flex-row flex-wrap justify-center gap-6 pl-0">
            {mealPlan?.map((recipe) => (
              <li key={recipe.id} tw="list-none">
                <RecipeCard recipe={recipe}>
                  <label tw="flex flex-row justify-end items-center gap-1">
                    <span tw="text-sm">Keep recipe</span>
                    <input
                      type="checkbox"
                      tw="toggle toggle-error"
                      value={JSON.stringify(recipe)}
                      {...register("lockedMealPlan")}
                    />
                  </label>
                </RecipeCard>
              </li>
            ))}
          </ul>
        )}

        <input
          type="submit"
          tw="btn btn-primary block w-full"
          value="Generate meal plan"
        />
      </form>
      {mealPlan && mealPlan.length > 0 && <ShoppingList mealPlan={mealPlan} />}
    </article>
  );
};

export default Roulette;
