/** @jsxImportSource @emotion/react */
import React from "react";
import { useQuery } from "react-query";
import "twin.macro";

import { getRecipes, Recipe } from "../../api/recipes";
import RecipeCard from "../../components/RecipeCard";

const Recipes: React.FC = () => {
  const { data, isLoading } = useQuery<Recipe[]>("recipes", getRecipes);
  if (isLoading || !data) {
    return <span tw="loading loading-dots loading-lg" />;
  }

  // todo: fix errored recipes. Probably add a button to reparse or something
  const sortedRecipes = data.filter((d) => d.status !== "error"); //.sort((a,b) => a.name.localeCompare(b.name))
  return (
    <article>
      <h2>Recipes</h2>
      <ul tw="flex flex-row flex-wrap gap-6">
        {sortedRecipes.map((recipe) => (
          <li key={recipe.id} tw="list-none">
            <RecipeCard recipe={recipe} />
          </li>
        ))}
      </ul>
    </article>
  );
};

export default Recipes;
