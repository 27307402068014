// src/styles/GlobalStyles.js
import React from "react";
import { GlobalStyles as BaseStyles } from "twin.macro";

const GlobalStyles = () => (
  <>
    <BaseStyles />
  </>
);

export default GlobalStyles;
