/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import "twin.macro";

import { Recipe as RecipeResponse } from "../api/recipes";

const stats = [
  { label: "Total Fat", key: "FAT", isSub: false, alwaysVisible: true },
  { label: "Saturated Fat", key: "FASAT", isSub: true },
  { label: "Trans Fat", key: "FATRN", isSub: true },
  { label: "Cholesterol", key: "CHOLE", isSub: false },
  { label: "Sodium", key: "NA", isSub: false },
  {
    label: "Total Carbohydrate",
    key: "CHOCDF",
    isSub: false,
    alwaysVisible: true,
  },
  { label: "Dietary Fiber", key: "FIBTG", isSub: true, alwaysVisible: true },
  { label: "Total Sugars", key: "SUGAR", isSub: true, alwaysVisible: true },
  {
    label: "Added Sugars",
    key: "SUGAR.added",
    isSub: true,
    alwaysVisible: true,
  },
  { label: "Protein", key: "PROCNT", isSub: false, alwaysVisible: true },
  { label: "Vitamins", key: "", isSub: false },
  { label: "Vitamin A", key: "VITA_RAE", isSub: true },
  { label: "Vitamin B-6", key: "VITB6A", isSub: true },
  { label: "Vitamin B-12", key: "VITB12", isSub: true },
  { label: "Vitamin C", key: "VITC", isSub: true },
  { label: "Vitamin D", key: "VITD", isSub: true },
  { label: "Vitamin K", key: "VITK1", isSub: true },
  { label: "Calcium", key: "CA", isSub: false },
  { label: "Iron", key: "FE", isSub: false },
  { label: "Potassium", key: "K", isSub: false },
  { label: "Magnesium", key: "MG", isSub: false },
];

const NutritionCard: React.FC<{ recipe: RecipeResponse }> = ({ recipe }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const numServings = recipe.yield || 1;
  return (
    <section tw="card bg-base-100 shadow rounded-lg min-w-[320px]">
      <div tw="bg-secondary rounded-t-lg px-6 flex flex-row justify-between items-baseline">
        <h3 tw="text-secondary-content card-title">Nutrition Facts</h3>
        <p tw="font-bold text-xs text-secondary-content">
          Serves: {numServings}
        </p>
      </div>
      <div tw="card-body py-0 px-6">
        <table>
          <thead>
            <tr tw="text-lg">
              <th colSpan={2}>Calories</th>
              <th tw="text-right font-normal">
                <div tw="text-xs">per serving</div>
                {Math.ceil(recipe.calories / numServings)}
              </th>
              <th tw="text-right font-normal">
                <div tw="text-xs">per entirety</div>
                {recipe.calories}
              </th>
            </tr>
            <tr tw="text-xs">
              <th colSpan={2}>per serving</th>
              <th tw="text-right">amount</th>
              <th tw="text-right">% daily</th>
            </tr>
          </thead>
          <tbody>
            {stats.map((stat, i) => {
              if (!isExpanded && !stat.alwaysVisible) {
                return null;
              }
              const nutrient = recipe.totalNutrients[stat.key];
              const daily = recipe.totalDaily[stat.key];
              return (
                <tr key={stat.label}>
                  {stat.isSub ? (
                    <>
                      <td></td>
                      <td>{stat.label}</td>
                    </>
                  ) : (
                    <>
                      <td colSpan={2} tw="font-bold">
                        {stat.label}
                      </td>
                    </>
                  )}
                  <td tw="text-right">
                    {nutrient?.quantity &&
                      (nutrient?.quantity / numServings).toFixed(1)}
                    {nutrient?.unit}
                  </td>
                  <td tw="text-right">
                    {daily?.quantity &&
                      (daily?.quantity / numServings).toFixed(1)}
                    {daily?.unit}
                  </td>
                </tr>
              );
            })}
            <tr>
              <td colSpan={4}>
                {isExpanded ? (
                  <button
                    tw="btn btn-link w-full"
                    onClick={() => setIsExpanded(false)}
                  >
                    - Collapse to see less
                  </button>
                ) : (
                  <button
                    tw="btn btn-link w-full"
                    onClick={() => setIsExpanded(true)}
                  >
                    + Expand to see more
                  </button>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default NutritionCard;
