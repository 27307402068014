/** @jsxImportSource @emotion/react */
import React from "react";
import "twin.macro";

import { useFormContext } from "react-hook-form";

export const convertBase64 = (file: FileList[0]) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

const RecipeMetaFields: React.FC = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <div tw="flex flex-col gap-6">
      <label htmlFor="name" tw="flex flex-col">
        <div>
          Name{" "}
          {errors.name && <span tw="text-error">This field is required</span>}
        </div>
        <input
          tw="input input-bordered"
          {...register("name", { required: true })}
        />
      </label>

      <label htmlFor="source" tw="flex flex-col">
        Source
        <input tw="input input-bordered" {...register("source")} />
      </label>
      <div tw="flex flex-row gap-6">
        <label htmlFor="yield" tw="flex flex-col w-24">
          Serves
          <input tw="input input-bordered" {...register("yield")} />
        </label>
        <label htmlFor="yield" tw="flex flex-col w-24">
          Difficulty
          <div tw="rating gap-1 py-2.5">
            <input
              type="radio"
              {...register("difficulty")}
              tw="mask mask-star-2 bg-green-400"
              value={1}
            />
            <input
              type="radio"
              {...register("difficulty")}
              tw="mask mask-star-2 bg-yellow-400"
              value={2}
            />
            <input
              type="radio"
              {...register("difficulty")}
              tw="mask mask-star-2 bg-red-400"
              value={3}
            />
          </div>
        </label>
        <label htmlFor="source" tw="flex flex-col">
          Use in Meal Planner Roulette
          <input
            type="checkbox"
            tw="toggle toggle-primary my-2.5"
            {...register("inMealPlanner")}
          />
        </label>
      </div>
      <label tw="form-control w-full max-w-xs">
        <div tw="label">Recipe Photo</div>
        <div tw="label text-xs">Leave blank for no change</div>
        <input
          type="file"
          tw="file-input file-input-bordered w-full max-w-xs"
          {...register("image", {
            validate: {
              size: (value) =>
                !value ||
                value.length < 1 ||
                value[0].size < 500000 ||
                "file size too large",
              filetype: (value) =>
                !value ||
                value.length < 1 ||
                [
                  "image/jpeg",
                  "image/png",
                  "image/gif",
                  "image/svg+xml",
                  "image/webp",
                ].includes(value[0].type) ||
                `file type (${value[0].type}) not accepted`,
            },
          })}
        />
        <div tw="label">
          <span tw="label-text-alt">
            Accepts jpg, png, gif, webp under 500 kb
          </span>
        </div>
        {errors.image && (
          <div tw="label">
            <span tw="label-text-alt text-error">
              Error: {errors.image.message as string}
            </span>
          </div>
        )}
      </label>
    </div>
  );
};
export default RecipeMetaFields;
