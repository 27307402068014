/** @jsxImportSource @emotion/react */
import React, { Fragment } from "react";
import "twin.macro";

import { Recipe } from "../../api/recipes";

import SendShoppingListToDiscordButton from "../../components/SendShoppingListToDiscordButton";
import useShoppingList, {
  gramToPoundsString,
} from "../../components/useShoppingList";

const ShoppingList: React.FC<{ mealPlan: Recipe[] }> = ({ mealPlan }) => {
  // todo: this gets duplicated in the send to discord button
  const { ingredientsWithWeight } = useShoppingList(mealPlan);

  return (
    <section>
      <div tw="flex flex-col md:flex-row justify-between items-center">
        <h3>Shopping List</h3>
        <SendShoppingListToDiscordButton mealPlan={mealPlan} />
      </div>
      <table tw="mt-0">
        <thead>
          <tr>
            <td colSpan={2}>Name</td>
            <td tw="break-keep">Amount</td>
            <td tw="break-keep">in grams</td>
          </tr>
        </thead>
        <tbody>
          {Object.entries(ingredientsWithWeight)
            .sort((a, b) => b[1].totalWeight - a[1].totalWeight)
            .map(([food, entry]) => (
              <Fragment key={food}>
                <tr tw="font-bold">
                  <td colSpan={2}>{food}</td>
                  <td tw="break-keep">{entry.imperialWeight}</td>
                  <td tw="break-keep">{Math.ceil(entry.totalWeight)}</td>
                </tr>
                {entry.ingredients.map((ingr) => (
                  <tr tw="opacity-70">
                    <td></td>
                    <td>{ingr.ogText}</td>
                    <td>{gramToPoundsString(ingr.weight)}</td>
                    <td>{Math.ceil(ingr.weight)}</td>
                  </tr>
                ))}
              </Fragment>
            ))}
        </tbody>
      </table>
    </section>
  );
};

export default ShoppingList;
