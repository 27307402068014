/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import "twin.macro";

import { FaDiscord } from "react-icons/fa";

import { Recipe } from "../api/recipes";

import { useMutation } from "react-query";
import { sendDiscordMessage, DISCORD_MSG_LIMIT } from "../api/external/discord";

import useShoppingList, { gramToPoundsString } from "./useShoppingList";

const SendShoppingListToDiscordButton: React.FC<
  { mealPlan: Recipe[] } & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ mealPlan, ...props }) => {
  const sendDiscordMessageMutation = useMutation<any, Error, string>(
    (message) => sendDiscordMessage(message)
  );

  const { ingredientsWithWeight } = useShoppingList(mealPlan);

  if (!process.env.REACT_APP_DISCORD_WEBHOOK) {
    return null;
  }

  const onSend = () => {
    const messages = Object.entries(ingredientsWithWeight)
      .sort((a, b) => b[1].totalWeight - a[1].totalWeight)
      .map(([food, entry]) =>
        [
          "> ",
          `**${food} - ${entry.imperialWeight}**`,
          "(",
          entry.ingredients
            .map(
              (ingr) => `${ingr.ogText} - ${gramToPoundsString(ingr.weight)}`
            )
            .join(","),
          ")",
        ].join(" ")
      )
      .reduce<string[]>((acc, msg) => {
        return acc.length > 0 &&
          acc[acc.length - 1].length + msg.length < DISCORD_MSG_LIMIT - 300
          ? acc.fill([acc[acc.length - 1], msg].join("\n"), acc.length - 1)
          : [...acc, msg];
      }, []);

    const recipeMsg = [
      " :meat_on_bone: :meat_on_bone: :meat_on_bone: **Meal Plan** :meat_on_bone: :meat_on_bone: :meat_on_bone:",
      ...mealPlan.map(
        (recipe) =>
          `${recipe.name} - ( ${window.location.origin}/#/recipes/${recipe.id} )`
      ),
      "**Shopping List**",
    ].join("\n");

    [recipeMsg, ...messages].forEach((message, i) =>
      setTimeout(() => sendDiscordMessageMutation.mutate(message), i * 300)
    );
  };

  return (
    <button tw="btn btn-primary" {...props} onClick={onSend}>
      <FaDiscord tw="w-6 h-6" /> Send shopping list to discord
    </button>
  );
};

export default SendShoppingListToDiscordButton;
