/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import "twin.macro";

import { useMutation, useQueryClient } from "react-query";

import { Recipe as RecipeResponse, putRecipe } from "../../api/recipes";
import { parseRecipe, EdamamRecipeResponse } from "../../api/external/edamam";

import { useForm, SubmitHandler } from "react-hook-form";

type FormInput = {
  ingredientsBlob: string;
};

const EditRecipeIngredients: React.FC<{
  recipe: RecipeResponse;
  onClose: () => void;
}> = ({ recipe, onClose }) => {
  const [edamamError, setEdamamError] = useState<string>();
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInput>({
    defaultValues: {
      ingredientsBlob: recipe.ingredients.map((i) => i?.text).join("\n"),
    },
  });

  const parseRecipeQuery = useMutation<
    EdamamRecipeResponse,
    Error,
    { title: string; ingredients: string[] }
  >(({ title, ingredients }) => parseRecipe(title, ingredients));

  const createRecipeMutation = useMutation<any, Error, any>((newRecipe) =>
    putRecipe(recipe.id, newRecipe)
  );

  const onSubmit: SubmitHandler<FormInput> = ({ ingredientsBlob }) => {
    parseRecipeQuery.mutate(
      { title: recipe.name, ingredients: ingredientsBlob.split("\n") },
      {
        onSuccess: (edmamParsedRecipe) => {
          if (edmamParsedRecipe.error) {
            return setEdamamError(edmamParsedRecipe.error);
          }

          createRecipeMutation.mutate(
            {
              ...recipe,
              ...edmamParsedRecipe,
              yield: recipe.yield,
            },
            {
              onSuccess: () => {
                queryClient.invalidateQueries("recipe");
                onClose();
              },
            }
          );
        },
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} tw="w-full flex flex-col gap-6">
      <label tw="flex flex-col">
        <div>
          {errors.ingredientsBlob && (
            <span tw="text-error">This field is required</span>
          )}
        </div>
        <textarea
          tw="textarea textarea-bordered h-96"
          {...register("ingredientsBlob", { required: true })}
        />
      </label>
      {edamamError && (
        <span tw="text-error">
          Ingredients parsing API error: {edamamError}
        </span>
      )}
      <input type="submit" tw="btn btn-primary" />
    </form>
  );
};
export default EditRecipeIngredients;
