import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { createHashRouter, RouterProvider } from "react-router-dom";

import GlobalStyles from "./GlobalStyles";

import Recipes from "./pages/recipes";
import RecipesShow from "./pages/recipes/RecipesShow";
import RecipesCreate from "./pages/recipes/RecipesCreate";
import Roulette from "./pages/roulette/Roulette";
import Layout from "./components/Layout";

const queryClient = new QueryClient();
function App() {
  const router = createHashRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "/recipes/:id",
          element: <RecipesShow />,
        },
        { path: "/recipes/create", element: <RecipesCreate /> },
        { path: "/roulette", element: <Roulette /> },
        {
          path: "/",
          element: <Recipes />,
        },
      ],
    },
  ]);
  return (
    <div className="App">
      <GlobalStyles />
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </div>
  );
}

export default App;
