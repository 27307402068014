/** @jsxImportSource @emotion/react */
import React from "react";
import "twin.macro";

import { useMutation, useQueryClient } from "react-query";

import { Recipe as RecipeResponse, putRecipe } from "../../api/recipes";

import { useForm, SubmitHandler } from "react-hook-form";

type FormInput = {
  directionsBlob: string;
};

const EditRecipeDirections: React.FC<{
  recipe: RecipeResponse;
  onClose: () => void;
}> = ({ recipe, onClose }) => {
  const queryClient = useQueryClient();
  const { register, handleSubmit } = useForm<FormInput>({
    defaultValues: { directionsBlob: recipe.directions.join("\n") },
  });

  const createRecipeMutation = useMutation<any, Error, any>((newRecipe) =>
    putRecipe(recipe.id, newRecipe)
  );

  const onSubmit: SubmitHandler<FormInput> = (values) => {
    createRecipeMutation.mutate(
      {
        ...recipe,
        directions: values.directionsBlob.split("\n"),
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("recipe");
          onClose();
        },
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} tw="w-full flex flex-col gap-6">
      <label tw="flex flex-col">
        Directions:
        <textarea
          tw="textarea textarea-bordered h-96"
          {...register("directionsBlob")}
        />
      </label>
      <input type="submit" tw="btn btn-primary" />
    </form>
  );
};
export default EditRecipeDirections;
